import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TasteBuddies = () => {
  const [email, setEmail] = useState('');
  const [playlistUrl, setPlaylistUrl] = useState('');
  const [stats, setStats] = useState({ playlistsAnalyzed: 0, successfulMatches: 0 });
  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    fetchStats();
    const interval = setInterval(fetchStats, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchStats = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tastebuddies-stats`);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus('processing');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/tastebuddies-analyze`, { email, playlistUrl });
      setSubmissionStatus(response.data.status);
      setEmail('');
      setPlaylistUrl('');
    } catch (error) {
      setSubmissionStatus('error');
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">TasteBuddies</h1>
      
      <div className="row mb-5">
        <div className="col-md-8 mx-auto">
          <h2>How It Works</h2>
          <ol className="list-group list-group-numbered">
            <li className="list-group-item">Share your playlist URL from Spotify or Apple Music.</li>
            <li className="list-group-item">Our algorithm analyzes your music taste based on the tracks in your playlist.</li>
            <li className="list-group-item">We compare your taste with other users to find potential music soulmates.</li>
            <li className="list-group-item">If we find at least 5 matching tracks with another user, you're invited to join our music battle platform!</li>
          </ol>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-6">
          <h2>Find Your Music Soulmates</h2>
          <p>Share your playlist and discover if you're ready to join epic music battles!</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="playlistUrl" className="form-label">Playlist URL</label>
              <input
                type="url"
                className="form-control"
                id="playlistUrl"
                value={playlistUrl}
                onChange={(e) => setPlaylistUrl(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary" disabled={submissionStatus === 'processing'}>
              {submissionStatus === 'processing' ? 'Analyzing...' : 'Analyze My Taste'}
            </button>
          </form>
          {submissionStatus === 'match_found' && (
            <div className="alert alert-success mt-3">
              Great news! We've found potential music soulmates for you. Check your email for registration instructions.
            </div>
          )}
          {submissionStatus === 'no_match' && (
            <div className="alert alert-info mt-3">
              We couldn't find a match at this time. We'll keep your playlist on file and notify you if a match is found later.
            </div>
          )}
          {submissionStatus === 'error' && (
            <div className="alert alert-danger mt-3">
              An error occurred while analyzing your playlist. Please try again later.
            </div>
          )}
        </div>
        <div className="col-md-6">
          <h2>TasteBuddies Stats</h2>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Playlists Analyzed</h5>
              <p className="card-text display-4">{stats.playlistsAnalyzed}</p>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <h5 className="card-title">Successful Matches</h5>
              <p className="card-text display-4">{stats.successfulMatches}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-8 mx-auto">
          <h2>Features</h2>
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Taste Analysis:</strong> Our sophisticated algorithm analyzes your playlist to create a unique taste profile.
            </li>
            <li className="list-group-item">
              <strong>Exclusive Matching:</strong> We only invite users who have potential music soulmates, ensuring a vibrant community.
            </li>
            <li className="list-group-item">
              <strong>Music Battles:</strong> Once matched, participate in exciting music battles with tracks that align with your taste.
            </li>
            <li className="list-group-item">
              <strong>Continuous Matching:</strong> Even if we don't find a match immediately, we keep your taste profile and notify you when a match is found.
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 mx-auto">
          <h2>FAQ</h2>
          <div className="accordion" id="tasteBuddiesFAQ">
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1">
                  What if I don't get matched immediately?
                </button>
              </h3>
              <div id="faq1" className="accordion-collapse collapse show">
                <div className="accordion-body">
                  Don't worry! We keep your taste profile on file and continuously look for new matches. We'll notify you by email as soon as we find a potential music soulmate for you.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2">
                  Can I update my playlist later?
                </button>
              </h3>
              <div id="faq2" className="accordion-collapse collapse">
                <div className="accordion-body">
                  Absolutely! If you haven't been matched yet, you can submit a new playlist for analysis. If you're already a member, you'll be able to update your taste profile from your account settings.
                </div>
              </div>
            </div>
            {/* Add more FAQ items as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasteBuddies;